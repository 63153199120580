import React, { Component } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import './AppFooter.css'

class AppFooter extends Component {
  render () {
    return (
      <Grid container className="footer" spacing={2}>
      <Grid item xs={12} sm={6} md={6} >
        <Box p={2} className='box1'>
          <Typography variant="h6" sx={{ marginBottom: '0.5em' }}>
            Refera
          </Typography>
          <Typography sx={{ fontSize: '0.90rem', fontWeight: 100 }}>
          Provide and manage referrals at Refera.co
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Box p={2} className='box2'>
          <Typography variant="h6" className='box-title'>
            Company
          </Typography>
          <Typography variant="body2" className='box-content'>
            About us
          </Typography>
          <Typography variant="body2" className='box-content'>
            Home
          </Typography>
          <Typography variant="body2" className='box-content'>
            Find Referrals
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Box p={2}>
          <Typography variant="h6">
            Support
          </Typography>
          <Typography variant="body2" className='box-content'>
            Help center
          </Typography>
          <Typography variant="body2" className='box-content'>
            FAQ
          </Typography>
          <Typography variant="body2" className='box-content'>
            Feedback
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <Box p={2}>
          <Typography variant="h6">
            Contact Us
          </Typography>
          <Typography variant="body2">
            Content for box 4
          </Typography>
        </Box>
      </Grid>
      <Box p={2} className='copyright' >
      <Typography variant="body2" className='copyright-claim'>
        &copy; 2024 Your Company. All rights reserved.
      </Typography>
      <Typography variant="body2" className='copyright-content'>
        Privacy Policy
      </Typography>
      <Typography variant="body2" className='copyright-content'>
        Terms of use
      </Typography>
      </Box>
    </Grid>
    )
  }
}

export default AppFooter
