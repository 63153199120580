import React, { useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import './Login.css'
// import { login } from '../../util/APIUtils.js'
import { GOOGLE_AUTH_URL } from '../../constants'
import googleLogo from '../../img/google-logo.png'
import { Divider, Grid, Box, Typography } from '@mui/material'

const Login = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state && location.state.error) {
      const timeout = setTimeout(() => {
        navigate(location.pathname, { state: {} })
      }, 100)

      return () => clearTimeout(timeout)
    }
    const header = document.getElementsByClassName('app-top-box')[0]
    const footer = document.getElementsByClassName('footer')[0]
    header.style.display = 'none'
    footer.style.display = 'none'
  }, [location, navigate])

  /* <Grid item xs={12} sm={6} md={12}>
          <LoginForm/>
    </Grid>
  */

  return (
    <div className='page-container'>
    <div className="login-container">
      <h2 className='title' color='#132471'>Login to Refera</h2>
      <Link to='/'>Back to refera.co</Link>
      <Grid container spacing={2} alignItems="center" marginTop='2em'>
        <Grid item xs={12} sm={6} md={12}>
          <SocialLogin/>
        </Grid>
        <Divider variant="middle" sx={{ backgroundColor: 'black', borderWidth: 1, width: '40%', opacity: '1' }} />
        <span>or</span>
        <Divider variant="middle" sx={{ backgroundColor: 'black', borderWidth: 1, width: '40%', opacity: '1' }} />
      </Grid>
    </div>
    <Box className='login-box'>
      <Typography fontFamily="Poppins" fontSize='1.75em'>Join the</Typography>
      <Typography fontFamily="Poppins" fontSize='1.75em'>Referral Network</Typography>
      <Typography fontFamily="Poppins" fontSize='1.75em'>and Empower Your Career.</Typography>
      <Typography fontFamily="Poppins" fontSize='1em' paddingTop='1em'>Create an account and achieve more together!</Typography>
    </Box>
    </div>
  )
}

function SocialLogin () {
  return (
    <div className="social-login">
      <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
        <img src={googleLogo} alt="Google" />Log in with Google
      </a>
    </div>
  )
}
/*
function LoginForm (props) {
  const [state, setState] = useState({
    email: '',
    password: ''
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target

    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const loginRequest = { ...state }

    login(loginRequest)
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken)
        // Alert.success("You're successfully logged in!")
        props.history.push('/')
      })
      .catch(error => {
        console.log(error)
        // Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!')
      })
  }

  return (
          <form onSubmit={handleSubmit}>
              <div className="form-item">
                  <input type="email" name="email"
                      className="form-control" placeholder="Email"
                      value={state.email} onChange={handleInputChange} required/>
              </div>
              <div className="form-item">
                  <input type="password" name="password"
                      className="form-control" placeholder="Password"
                      value={state.password} onChange={handleInputChange} required/>
              </div>
              <div className="form-item">
                  <button type="submit" className="login-button">Login</button>
              </div>
          </form>
  )
}
*/
export default Login
