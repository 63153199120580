import React, { useState } from 'react'
import { API_BASE_URL } from '../constants'
import { request } from '../util/APIUtils'
import { NavLink } from 'react-router-dom'

const Premium = (props) => {
  const [checkoutUrl, setCheckoutUrl] = useState('')
  const handleCheckout = () => {
    const jsonUrl = request({
      url: API_BASE_URL + '/checkout/hosted',
      method: 'POST',
      body: JSON.stringify(props.currentUser)
    }, { 'Content-Type': 'application/json' })

    jsonUrl.then(response => {
      setCheckoutUrl(response.url)
    })
  }

  return (
    <NavLink to={checkoutUrl} onClick={handleCheckout}>checkout</NavLink>
  )
}
export default Premium
