import React, { Component } from 'react'
import './Signup.css'
import { Navigate } from 'react-router-dom'
import { GOOGLE_AUTH_URL } from '../../constants'
// import { signup } from '../../util/APIUtils'
import googleLogo from '../../img/google-logo.png'

class Signup extends Component {
  render () {
    if (this.props.authenticated) {
      return <Navigate
                to={{
                  pathname: '/',
                  state: { from: this.props.location }
                }} />
    }
    // <SignUpForm {...this.props}/>
    return (
            <div className="signup-container">
                <div className="signup-content">
                    <h1 className="signup-title">Signup to Refera.co</h1>
                  <SocialSignup />
                </div>
            </div>
    )
  }
}

class SocialSignup extends Component {
  render () {
    return (
            <div className="social-signup">
                <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                    <img src={googleLogo} alt="Google" /> Sign up with Google</a>
            </div>
    )
  }
}
/*
function SignUpForm (props) {
  const [state, setState] = useState({
    name: '',
    email: '',
    password: ''
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target

    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const signUpRequest = { ...state }

    signup(signUpRequest)
      .then(response => {
        // Alert.success("You're successfully registered. Please login to continue!")
        props.history.push('/login')
      })
      .catch(error => {
        console.log(error)
        // Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!')
        props.history.push('/')
      })
  }

  return (
    <form onSubmit={handleSubmit}>
        <label>
            Name:
            <input type="text" name="name" value={state.name} onChange={handleInputChange} />
        </label>
        <br />
        <label>
            Email:
            <input type="text" name="email" value={state.email} onChange={handleInputChange} />
        </label>
        <br />
        <label>
            Password:
            <input type="password" name="password" value={state.password} onChange={handleInputChange} />
        </label>
        <br />
        <button type="submit">Sign Up</button>
    </form>
  )
}
*/
export default Signup
