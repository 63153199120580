import React, { useState, useEffect } from 'react'
import { request } from '../util/APIUtils'
import { API_BASE_URL } from '../constants'
import Sidebar from '../common/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import './ReferralList.css'
import ApplicantForm from './ApplicantForm'

function ReferralList () {
  const [List, setList] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [yoefilter, setyoeFilters] = useState([])
  const [locationfilter, setlocationFilter] = useState([])

  useEffect(() => {
    const json = request({
      url: API_BASE_URL + '/referrals',
      method: 'GET'

    })

    json.then(response => {
      setList(response)
    })
  }, [List])

  const updateYoe = (newyoe) => {
    setyoeFilters(newyoe)
  }

  const updateLocation = (newLocation) => {
    setlocationFilter(newLocation)
    console.log(locationfilter)
  }

  const logo = 'https://logo.uplead.com/'

  return (
    <div className='body'>
      <Sidebar
        yoefilter={yoefilter}
        locationfilter={locationfilter}
        updateLocation={updateLocation}
        updateYoe={updateYoe}
      />
      <div className='cards'>
        <ul className='ul-class'>
          {List.map(item => (

            <li key={item.email} className='li-class'>
              <div className='content'>
                  <div className='image'>
                  <div className="logo-container">
                    <img src={logo + item.company.toLowerCase() + '.com'}></img>
                  </div>
                  </div>
                  <div>
                  <br></br>
                  <span className='heading'>{item.name} works as <b>{item.jobRole}</b> at {item.company}</span>
                  <br></br>
                  <span className='heading'>can refer for</span>
                  <ul className='ul-yoe'>
                  { item.yoe.map(i => (
                    <li key={i} className='yoe'>{i}</li>
                  ))}
                  </ul>
                  <FontAwesomeIcon icon={faLocationDot} className='location-icon'/><span className='location'>{item.location}</span>
                  </div>
              </div>
              <div className='apply-button'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <ApplicantForm id={item.id}/>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ReferralList
