import React, { useState, useEffect } from 'react'
import './ReferralPost.css'
import { Autocomplete, TextField } from '@mui/material'
import { API_BASE_URL } from '../constants'
import { request } from '../util/APIUtils'

const ReferralPost = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [Yoe, setYoe] = useState(['Entry-level', 'Junior-level', 'Mid-level', 'Senior-level'])
  const [countries, setCountries] = useState([])
  const [formData, setFormData] = useState({
    userid: '',
    name: '',
    company: '',
    location: '',
    yoe: [],
    jobRole: '',
    numberOfApplicants: ''
  })
  // eslint-disable-next-line no-unused-vars
  const [jobRole, setJobRole] = useState([])

  useEffect(() => {
    // Fetch skills data from the API
    const fetchData = async () => {
      try {
        const jobRoleresponse = await request({
          url: API_BASE_URL + '/json',
          method: 'GET'
        })

        if (jobRoleresponse.jobTitles) {
          setJobRole(jobRoleresponse.jobTitles.map(skill => skill))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => setCountries(data))
      .catch(error => console.error('Error fetching countries:', error))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      request({
        url: API_BASE_URL + '/create-referral',
        method: 'POST',
        body: JSON.stringify(formData)
      }, { 'Content-Type': 'application/json' })
      console.log(formData)
    } catch (error) {
      console.log(error)
    }
    window.alert('Form submitted successfully!')
  }

  const handleInputChange = (e, selectedvalue) => {
    const { name, value } = e.target
    formData.userid = props.currentUser.id
    formData.name = props.currentUser.name
    if (selectedvalue) {
      formData.yoe = selectedvalue
    }
    const fetchcompany = async () => {
      try {
        const response = await fetch(`https://company.clearbit.com/v2/companies/find?domain=${formData.company}.com`, {
          headers: {
            Authorization: 'Bearer sk_60880a8c7d1ee636cd33067bbdf222b8'
          }
        })
        if (response.ok) {
          const data = await response.json()
          formData.company = data.name
        } else {
          // Handle the case when the response status is not OK
          console.error(`Failed to fetch company data. Status: ${response.status}`)
        }
      } catch (error) {
        // Handle network or other errors
        console.error('Error fetching company data:', error)
      }
    }
    if (formData.company !== '') {
      fetchcompany()
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleLocationChange = (e, selectedvalue) => {
    if (selectedvalue) {
      formData.location = selectedvalue.name.common
    }
  }

  const handleJobRoleChange = (e, selectedvalue) => {
    if (selectedvalue) {
      formData.jobRole = selectedvalue
    }
  }

  return (
    <div className='form-container'>
      <br />
      <form onSubmit={handleSubmit} className='form'>
        <label>Company: </label>
        <input type='text' onChange={handleInputChange} name='company' value={formData.company}></input>
        <label>Job Role: </label>
        <Autocomplete
          disablePortal
          size='small'
          id="combo-box-demo"
          options={jobRole}
          value={formData.jobRole}
          getOptionLabel={(option) => option }
          onChange={handleJobRoleChange}
          sx={{ width: '38em', boxShadow: 'none', border: 'none', '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { width: '40em' } }}
          renderInput={ (params) => <TextField sx= { {
            '& .MuiInputLabel-root': { color: 'green', boxShadow: 'none' }, // styles the label
            '& .MuiInputBase-input': { border: 'none', margin: '0', boxSizing: 'border-box' },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { border: 'none' }
            }
          }}{...params}/>}
        />
        <label>Select position you are willing to refer: </label>
        <Autocomplete
          disablePortal
          multiple
          size='small'
          id="combo-box-demo"
          options={Yoe}
          value={formData.yoe}
          onChange={handleInputChange}
          sx={{ width: '38em', boxShadow: 'none', border: 'none', '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { width: '40em' } }}
          renderInput={ (params) => <TextField sx= { {
            '& .MuiInputLabel-root': { color: 'green', boxShadow: 'none' }, // styles the label
            '& .MuiInputBase-input': { border: 'none', margin: '0', boxSizing: 'border-box' },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { border: 'none' }
            }
          }}{...params}/>}
        />
        <label>Location: </label>
        <Autocomplete
          disablePortal
          size='small'
          id="combo-box-demo"
          options={countries}
          value={formData.location}
          getOptionLabel={(option) => option && option.name.common}
          onChange={handleLocationChange}
          sx={{ width: '38em', boxShadow: 'none', border: 'none', '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { width: '40em' } }}
          renderInput={ (params) => <TextField sx= { {
            '& .MuiInputLabel-root': { color: 'green', boxShadow: 'none' }, // styles the label
            '& .MuiInputBase-input': { border: 'none', margin: '0', boxSizing: 'border-box' },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { border: 'none' }
            }
          }}{...params}/>}
        />
        <label> Number of applicants allowed: </label>
        <select id="number" className='applicant_dropdown'>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
        </select>
        <button type="submit">Create Referral</button>
      </form>
      <br></br>
    </div>

  )
}

export default ReferralPost
