import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Autocomplete, Box, TextField } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './Sidebar.css'

function Sidebar (props) {
  const [countries, setCountries] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [yoe, setYoe] = useState(['Entry-level', 'Junior-level', 'Senior-level'])

  useEffect(() => {
    // Fetch countries from the API
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => setCountries(data))
      .catch(error => console.error('Error fetching countries:', error))
  }, [])

  const handleYoeSelection = (event, newfilter) => {
    props.updateYoe(newfilter)
  }

  const handleLocationSelection = (event, newfilter) => {
    props.updateLocation(newfilter)
  }

  return (

    <div className='Sidebar'>
      <p>Filter By</p>
      <Accordion style= {{ backgroundColor: 'transparent', boxShadow: 'none', borderBottom: '0.075em solid gray', borderRadius: '0.1em', color: '#363636' }}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontSize: '0.90em', padding: '0' }}>Company</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style= {{ backgroundColor: 'transparent', boxShadow: 'none', borderBottom: '0.075em solid gray', borderRadius: '0.1em', color: '#363636' }}
        sx={{ '& .MuiAccordion-root': { backgroundColor: 'red' } }}
      >
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontSize: '0.90em', padding: '0' }}>Year of experience </Typography>
        </AccordionSummary>
        <Autocomplete
          disablePortal
          multiple
          size='small'
          id="combo-box-demo"
          options={yoe}
          value={props.yoefilter}
          onChange={handleYoeSelection}
          sx={{ width: '12em', boxShadow: 'none', border: 'none' }}
          renderInput={ (params) => <TextField sx= { {
            '& .MuiInputLabel-root': { color: 'green', boxShadow: 'none' }, // styles the label
            '& .MuiInputBase-input': { border: 'none', margin: '0', boxSizing: 'border-box' },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderColor: 'black' }
            }
          }}{...params}/>}
        />
      </Accordion>
      <Accordion className ='accordion' style= {{ backgroundColor: 'transparent', boxShadow: 'none', borderBottom: '0.075em solid gray', borderRadius: '0.1em', color: '#363636' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontSize: '0.90em', padding: '0' }}>Location</Typography>
        </AccordionSummary>
          <Autocomplete
          multiple
          disablePortal
          id="location-box"
          size='small'
          sx={{ width: '12em', boxShadow: 'none', border: 'none' }}
          options={countries}
          value={props.locationfilter}
          onChange={handleLocationSelection}
          autoHighlight
          getOptionLabel={(option) => option.name.common}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.name.common}
            </Box>
          )}
          renderInput={(params) => <TextField {...params} sx= { {
            '& .MuiInputBase-root': { backgroundColor: 'transparent', boxShadow: 'none', borderColor: 'transparent' }, // styles the label
            '& .MuiInputBase-input': { borderColor: 'blue', border: 'none', margin: '0' },
            '& .MuiOutlinedInput-root': {
              '& > fieldset': { borderColor: 'black' }
            }
          }}/>}
          />
      </Accordion>
    </div>

  )
}

export default Sidebar
