import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { API_BASE_URL } from '../../constants'
import { request } from '../../util/APIUtils'
import './Profile.css'

const Profile = (props) => {
  const [Referrals, setReferrals] = useState([])
  const [appliedReferrals, setAppliedReferrals] = useState([])
  // const logo = "https://logo.clearbit.com/";

  useEffect(() => {
    const json = request({
      url: API_BASE_URL + '/referrals',
      method: 'GET'

    })

    const json1 = request({
      url: API_BASE_URL + '/appliedreferrals' + props.currentUser.id,
      method: 'GET'
    })

    json.then(response => {
      setReferrals(response.filter(referral => referral.userid === props.currentUser.id))
      console.log(Referrals)
    })
      .catch((error) => {
        console.error('Error fetching referrals:', error)
      })

    json1.then(response => {
      setAppliedReferrals(response)
      console.log(Referrals)
    })
      .catch((error) => {
        console.error('Error fetching referrals:', error)
      })
  }, [])

  return (
        <div className="profile-container">

            <div className="profile-info">
                <span className='title'>Applied Referrals</span><br></br>
                { appliedReferrals.length !== 0
                  ? appliedReferrals.map(item => (
                    <li key={item.userid}>
                      <span>view status</span>
                    </li>))
                  : <span>You have not applied to any referrals</span>
                }
            </div>
            <div className='referralposts'>
            <span className='title'>Referrals Posted </span><br></br>
            <div className='created-referral'>
                <ul>
                    {Referrals.map(item => (

                        <li key={item.userid}>

                            <span>View Applicants at your post </span>

                            <Link to={'/applicants/' + item.id}>
                                <button type="radio" className="apply-radio" name="view-applicants">View Applicants</button>
                            </Link>

                        </li>
                    ))}
                </ul>
            </div>
            </div>
        </div>
  )
}
export default Profile
