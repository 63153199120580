import React, { useEffect, useState } from 'react'
import { Fade, Modal } from '@mui/material'
import { API_BASE_URL } from '../constants'
import { request } from '../util/APIUtils'
import './ApplicantForm.css'

const ApplicantForm = (props) => {
  const [open, setOpen] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [referral, setReferral] = useState('')

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    resume: null,
    skills: '',
    jobId: ''
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const formDataToSend = new FormData()
      formDataToSend.append('name', formData.name)
      formDataToSend.append('email', formData.email)
      formDataToSend.append('resume', formData.resume) // Append the file object directly
      formDataToSend.append('skills', formData.skills)
      formDataToSend.append('jobId', formData.jobId)
      console.log(formData)
      request({
        url: `${API_BASE_URL}/apply/${props.id}`,
        method: 'POST',
        body: formDataToSend
      })
    } catch (error) {
      console.log(formData)
      console.log(error)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleResumeUpload = (e) => {
    const file = e.target.files[0]
    setFormData((prevData) => ({
      ...prevData,
      resume: file
    }))
  }

  useEffect(() => {
    const referralid = request({
      url: API_BASE_URL + '/referral/' + props.id,
      method: 'GET'
    })
    referralid.then(response => {
      setReferral(response)
    })
    const json = async () => {
      try {
        const response = await fetch('https://company.clearbit.com/v2/companies/find?domain=' + props.companyname + '.com', {
          headers: {
            Authorization: `Basic ${btoa('sk_60880a8c7d1ee636cd33067bbdf222b8' + ':')}`
          }
        })
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const result = await response.json()
        setData(result)
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    }
    json()
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
    <button className='apply-radio' onClick={handleOpen}>Ask for a referral</button>
    {data
      ? <Modal open={open} onClose={handleClose} disableScrollLock >
    <Fade in={open}>
          <div className='detail-container'>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
            <label> Applicant Name: </label>
            <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />

          <label>Applicant Email:</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            pattern=".+@.+"
          />
          <br />
          <label> Resume (PDF only): </label>
          <input
            type="file"
            accept=".pdf"
            name="resume"
            onChange={handleResumeUpload}
          />
          <br />
          <label>
            Paste the Job listing URL from the career`&apos;`s page :
          </label>
          <input
            type="text"
            name="jobId"
            value={formData.jobId}
            onChange={handleInputChange}
          />
          <label>Skills: </label>
          <input
            type="text"
            name="skills"
            value={formData.skills}
            onChange={handleInputChange}
          />
          </form>
          <button className='close' onClick={handleClose}>Close</button>
          <button className='close' style={{ backgroundColor: 'purple' }}>Submit</button>
          </div>
    </Fade>
    </Modal>
      : <Modal open={open} onClose={handleClose}>
        <p>no data</p>
      </Modal>
    }
    </>
  )
}
export default ApplicantForm
